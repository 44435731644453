import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import get from 'lodash.get'
import { Box, Link } from '@sharecover-co/ui'

import { addFacts } from '../../actions/session'

import CheckoutLayout from '../layouts/CheckoutLayout'
import Pricing from '../base/Pricing'
import useMediaQuery from 'use-mediaquery'
import Registration from './rideshare/Registration'
import Vehicle from './rideshare/Vehicle'
import InsuredValue from './rideshare/InsuredValue'
import ParkedAddress from './rideshare/ParkedAddress'
import Usage from './rideshare/Usage'
import Claims from './rideshare/Claims'
import Driver from './rideshare/Driver'
import Platforms from './rideshare/Platforms'
import AdditionalDriver from './rideshare/AdditionalDriver'
import Excess from './rideshare/Excess'
import Extras from './rideshare/Extras'
import Dates from './rideshare/Dates'
import Other from './rideshare/Other'
import BaseConditions from './tandcs/BaseConditions'

const steps = [
    {
        route: {
            path: '/rideshare',
            exact: true
        },
        Component: Vehicle,
        title: 'Vehicle Details'
    },

    {
        route: {
            path: '/rideshare/insured-value'
        },
        Component: InsuredValue,
        title: 'Vehicle Details'
    },
    {
        route: {
            path: '/rideshare/registration'
        },
        Component: Registration,
        title: 'Vehicle Details'
    },
    {
        route: {
            path: '/rideshare/parked-address'
        },
        Component: ParkedAddress,
        title: 'Vehicle Details'
    },
    {
        route: {
            path: '/rideshare/usage'
        },
        Component: Usage,
        title: 'Vehicle Details'
    },
    {
        route: {
            path: '/rideshare/claims'
        },
        Component: Claims,
        title: 'Driver Details'
    },
    {
        route: {
            path: '/rideshare/driver'
        },
        Component: Driver,
        title: 'Driver Details'
    },
    {
        route: {
            path: '/rideshare/platforms'
        },
        Component: Platforms,
        title: 'Driver Details'
    },
    {
        route: {
            path: '/rideshare/additional-driver'
        },
        Component: AdditionalDriver,
        title: 'Additional Driver Details'
    },
    {
        route: {
            path: '/rideshare/excess'
        },
        Component: Excess,
        title: 'Policy Details'
    },
    {
        route: {
            path: '/rideshare/extras'
        },
        Component: Extras,
        title: 'Policy Details'
    },
    {
        route: {
            path: '/rideshare/dates'
        },
        Component: Dates,
        title: 'Policy Details'
    },
    {
        route: {
            path: '/rideshare/other'
        },
        Component: Other,
        title: 'Declarations'
    }
]

const Rideshare = ({ addFacts }) => {
    React.useEffect(() => {
        window.location =
            process.env.REACT_APP_MARKETING_URL + '/ridecover-policyholders'
    }, [])
    const matches = useMediaQuery('(max-width: 767px)')
    return (
        <Switch>
            {steps.map(({ Component, ...step }, i) => (
                <Route
                    key={step.route.path}
                    {...step.route}
                    render={(routeProps) => {
                        const previous = get(steps, `[${i - 1}].route.path`)

                        return (
                            <Box
                                sx={
                                    matches
                                        ? {
                                              boxSizing: 'border-box',
                                              margin: '0px',
                                              minWidth: '0px',
                                              width: '100%'
                                          }
                                        : {
                                              boxSizing: 'border-box',
                                              margin: '0px',
                                              minWidth: '0px',
                                              width: '100%',

                                              position: 'fixed'
                                              // height: '100vh'
                                          }
                                }
                            >
                                <CheckoutLayout
                                    title={step.title}
                                    progress={((i / steps.length) * 100) / 1.5}
                                    pricing={<Pricing />}
                                >
                                    <Component
                                        {...routeProps}
                                        addFacts={addFacts}
                                        next={get(
                                            steps,
                                            `[${i + 1}].route.path`,
                                            '/review'
                                        )}
                                    />
                                    {previous ? (
                                        <Box sx={{ my: 3 }}>
                                            <Link
                                                to={previous}
                                                sx={{
                                                    variant:
                                                        'links.buttonOutline'
                                                }}
                                            >
                                                Back
                                            </Link>
                                        </Box>
                                    ) : (
                                        <>
                                            <br />
                                        </>
                                    )}
                                    <BaseConditions />
                                </CheckoutLayout>
                            </Box>
                        )
                    }}
                />
            ))}
        </Switch>
    )
}

const mapDispatchToProps = {
    addFacts
}

export default connect(null, mapDispatchToProps)(Rideshare)
