import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link } from '@sharecover-co/ui'
import { nanoid } from 'nanoid'

const Product = ({ product }) => {
    if (!product) {
        return null
    }

    const { name, description, documents, id } = product
    let descriptionComponent = <></>
    if (id === process.env.REACT_APP_RIDECOVER_PRODUCT_ID) {
        descriptionComponent = <p>{description}</p>
    } else if (id === process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID) {
        descriptionComponent = (
            <p>
                The Short Stay Insurance Policy offers cover for hosts for
                either 6 or 12 months against guest-related risks and liability.
                If you are interested in taking out our Pay-Per-Night policy,
                please click{' '}
                <a
                    href={`/new/property?productId=${process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID}`}
                >
                    here
                </a>
            </p>
        )
    } else {
        descriptionComponent = (
            <p>
                The Pay-Per-Night Short-stay Insurance Policy offers cover for
                hosts for between 1 – 90 nights against guest-related risks and
                liability. If you are interested in taking out either our 6- or
                12-month policy, please click{' '}
                <a
                    href={`/new/property?productId=${process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID}`}
                >
                    here
                </a>
            </p>
        )
    }
    if (id === process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID) {
        return null
    } else {
        return (
            <>
                <Heading sx={{ fontSize: [1, 1] }}>{name}</Heading>
                {description && descriptionComponent}
                {documents.map((doc) => (
                    <p key={nanoid()}>
                        <Link href={doc.url} target="_blank">
                            {doc.name}
                        </Link>
                    </p>
                ))}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    ...state.product
})

export default connect(mapStateToProps)(Product)
